<template>
  <div class="mainwrap questionnaire-edit" v-loading="loading">
    <div class="title">{{ title }}</div>
    <div class="formwrap">
      <el-form
          :model="formData"
          :rules="rules"
          ref="gameForm"
          label-width="190px"
          label-position="left"
      >
        <el-form-item label="名稱：" prop="name">
          <el-input
              v-model="formData.name"
              placeholder="請輸入名稱"
          ></el-input>
        </el-form-item>
        <el-form-item label="遊戲類型：" prop="type">
          <el-select
              v-model="formData.type"
              placeholder="請選擇遊戲類型"
          >
            <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活動規則：" prop="conditionType">
          <el-select
              v-model="formData.conditionType"
              placeholder="請選擇活動規則"
          >
            <el-option
                v-for="item in ruleList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活動規則觸發條件值：" prop="conditionValue">
          <el-input placeholder="請輸入活動規則觸發條件值" v-model="formData.conditionValue" controls-position="right" :min="1" :max="10000000000"></el-input>
<!--          <span>{{formData.conditionType === 0 ?'次': '元'}}</span>-->
        </el-form-item>
        <el-form-item label="滿足觸發條件後遊戲次數：" prop="gameNum">
          <el-input placeholder="請輸入遊戲次數" v-model="formData.gameNum" controls-position="right" :min="1" :max="10000000000"></el-input>
        </el-form-item>
        <el-form-item label="活動時間" prop="time">
          <el-date-picker
              @change="valueDate"
              :picker-options="pickerOptions"
              v-model="formData.time"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetimerange"
              range-separator="至"
              :default-time="[date, '23:59:59']"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="遊玩時間" prop="playTime">
          <el-date-picker
              @change="valueDate"
              v-model="formData.playTime"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              :default-time="[date, '23:59:59']"
              :picker-options="pickerOptions"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item class="table_jl" label="發放獎勵：" prop="gameRewardItems">
         <el-button style="margin-bottom: 20px;" @click="addPrize()">新增獎項</el-button>
          <span class="title_tip">比例獎項(總數需是100)</span>
          <el-table
              :data="formData.gameRewardItems"
              style="width: 100%">
            <el-table-column
                prop="proportion"
                label="比例(總數需是100)">
              <template slot-scope="scope">
                <el-input placeholder="请输入比例" v-model="scope.row.proportion" controls-position="right" :min="1" :max="100"></el-input>
              </template>
            </el-table-column>
            <el-table-column
                prop="type"
                label="獎品內容">
              <template slot-scope="scope">
                <el-select
                    @change="selectType(scope.row,scope.$index)"
                    v-model="scope.row.type"
                    placeholder="請選擇獎品"
                >
                  <el-option
                      v-for="item in prizeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
                prop="value"
                label="點數">
              <template slot-scope="scope">
                <el-input v-if="scope.row.type === 1" label="請輸入點數" v-model="scope.row.value" controls-position="right" :min="1" :max="10000000000"></el-input>
              </template>
            </el-table-column>
            <el-table-column
                prop="limit"
                label="最大上限（非必填）"
                width="160">
              <template slot-scope="scope">
                <el-input v-model="scope.row.limit" controls-position="right" :min="1" :max="1000" label="請輸入限量"></el-input>
              </template>
            </el-table-column>
            <el-table-column
                prop="rewardName"
                label="優惠券">
              <template slot-scope="scope">
                <el-select
                    v-if="scope.row.type === 2"
                    v-model="scope.row.value"
                    placeholder="請選擇優惠券"
                >
                  <el-option
                      v-for="item in couponList"
                      :key="item.key"
                      :label="item.value"
                      :value="item.key"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="120">
              <template slot-scope="scope">
                <el-button
                    @click.native.prevent="deleteRow(scope.$index, formData.gameRewardItems)"
                    type="text"
                    size="small">
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="推播標題：" prop="messageTitle">
          <el-input
              v-model="formData.messageTitle"
              placeholder="請輸入推播標題"
          ></el-input>
        </el-form-item>
        <el-form-item label="推播副標題：" prop="messageSubTitle">
          <el-input
              v-model="formData.messageSubTitle"
              placeholder="請輸入推播副標題"
          ></el-input>
        </el-form-item>
        <el-form-item label="活動介紹：" prop="introduce">
          <el-input
              type="textarea"
              v-model="formData.introduce"
              placeholder="請輸入活動介紹"
          ></el-input>
        </el-form-item>
        <el-form-item label="推播列表圖片：" prop="messageListImageUrl">
          <el-upload
              class="img-uploader"
              action=""
              :auto-upload="false"
              :show-file-list="false"
              :on-change="uploadImage"
          >
            <img
                v-if="formData.messageListImageUrl"
                :src="formData.messageListImageUrl"
                class="imgbox"
            />
            <i v-else class="el-icon-plus"></i>
            <div
                @click.stop.prevent
                v-if="formData.messageListImageUrl"
                class="deletewrap"
            >
              <i class="el-icon-delete" @click="removeImage(1)"></i>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上傳jpg/png文件，且不超過2MB，建議圖片尺寸為376*306
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="廣告圖：" prop="bannerImage">
          <el-upload
              class="img-uploader"
              action=""
              :auto-upload="false"
              :show-file-list="false"
              :on-change="uploadImage2"
          >
            <img
                v-if="formData.bannerImage"
                :src="formData.bannerImage"
                class="imgbox"
            />
            <i v-else class="el-icon-plus"></i>
            <div
                @click.stop.prevent
                v-if="formData.bannerImage"
                class="deletewrap"
            >
              <i class="el-icon-delete" @click="removeImage(2)"></i>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上傳jpg/png文件，且不超過2MB，建議圖片尺寸為376*306
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="背景圖：" prop="backgroundImage">
          <el-upload
              class="img-uploader"
              action=""
              :auto-upload="false"
              :show-file-list="false"
              :on-change="uploadImage3"
          >
            <img
                v-if="formData.backgroundImage"
                :src="formData.backgroundImage"
                class="imgbox"
            />
            <i v-else class="el-icon-plus"></i>
            <div
                @click.stop.prevent
                v-if="formData.backgroundImage"
                class="deletewrap"
            >
              <i class="el-icon-delete" @click="removeImage(3)"></i>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上傳jpg/png文件，且不超過2MB，建議圖片尺寸為376*1552
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="封面圖：" prop="coverImage">
          <el-upload
              class="img-uploader"
              action=""
              :auto-upload="false"
              :show-file-list="false"
              :on-change="uploadImage4"
          >
            <img
                v-if="formData.coverImage"
                :src="formData.coverImage"
                class="imgbox"
            />
            <i v-else class="el-icon-plus"></i>
            <div
                @click.stop.prevent
                v-if="formData.coverImage"
                class="deletewrap"
            >
              <i class="el-icon-delete" @click="removeImage(4)"></i>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上傳jpg/png文件，且不超過2MB，建議圖片尺寸為600*600
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="獎品圖：" prop="prizeImage">
          <el-upload
              class="img-uploader"
              action=""
              :auto-upload="false"
              :show-file-list="false"
              :on-change="uploadImage5"
          >
            <img
                v-if="formData.prizeImage"
                :src="formData.prizeImage"
                class="imgbox"
            />
            <i v-else class="el-icon-plus"></i>
            <div
                @click.stop.prevent
                v-if="formData.prizeImage"
                class="deletewrap"
            >
              <i class="el-icon-delete" @click="removeImage(5)"></i>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上傳jpg/png文件，且不超過2MB，建議圖片尺寸為600*600
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="啓用狀態" prop="isEnable">
          <el-switch v-model="formData.isEnable"></el-switch>
        </el-form-item>
        <el-form-item label="每人可參與活動次數：" prop="activityNum">
          <el-input v-model="formData.activityNum" controls-position="right" :min="1" :max="1000" label="請輸入參與活動次數"></el-input>
        </el-form-item>
        <el-form-item label="品牌：">
          <el-select
              v-model="formData.brand"
              placeholder="請選擇品牌"
              @change="changeBrand"
          >
            <el-option label="請選擇品牌" value=""></el-option>
            <el-option
                v-for="item in allBrandList"
                :key="item.key"
                :label="item.value"
                :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="店別：" class="shopWrap">
          <el-input
              v-model="formData.shopListText"
              disabled
              placeholder="請選擇店別"
          ></el-input>
          <el-button type="primary" :disabled="isMore" @click="openShopDialog"
          >選擇</el-button
          >
        </el-form-item>
        <el-form-item label="備註：" prop="description">
          <el-input
              type="textarea"
              v-model="formData.description"
              placeholder="請輸入備注"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div class="btnwrap">
      <div class="btnlist">
        <el-button type="primary" @click="onSubmit">提交</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </div>

    <!--選擇店別-->
    <el-dialog
        title="選擇店別"
        @close="hideShopDialog"
        :visible.sync="shopDialog.show"
        width="40%"
    >
      <div class="searchBox">
        <el-input v-model="shopDialog.search"></el-input>
        <el-button type="primary" @click="searchShop">搜索</el-button>
      </div>
      <el-table
          :data="shopData"
          @selection-change="handleSelectionChange"
          row-key="key"
          ref="multipleTable"
      >
        <el-table-column
            type="selection"
            width="100"
            :reserve-selection="true"
        ></el-table-column>
        <el-table-column prop="value" label="名稱"></el-table-column>
      </el-table>
      <div class="bottom">
        <div style="margin-top: 20px">
          <el-button type="primary" @click="submitShopDialog">確定</el-button>
          <el-button @click="hideShopDialog">取消</el-button>
        </div>
        <div class="pagination">
          <el-pagination
              background
              :hide-on-single-page="shopPager.isSingle"
              layout="prev, pager, next,jumper"
              @current-change="changeShopPage"
              :page-size="shopPager.size"
              :current-page="shopPager.current"
              :total="shopPager.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as api from "./api";
import { tableDataPagination } from "@/utils/common";
import {editGame, gameActivityDetail, getGameCoupon} from "./api";
import {getImageInfo} from "../../utils/common";

export default {
  data() {
    const validateGame = (rule, value, callback) => {
      const gameItem = this.formData.gameRewardItems;
      let isTrue = false;
      let totalProportion = 0;
      gameItem?.forEach((item)=>{
        isTrue = true;
        totalProportion = parseInt(item.proportion)  + totalProportion
        if((item.type !== 3 && item.value && item.type && item.proportion)||(item.type === 3 && item.type && item.proportion)){
          isTrue = false
        }
      })
      // console.log(totalProportion)
      if(totalProportion != 100){
        isTrue = true
      }else{
        isTrue = false
      }
      if (isTrue) {
        callback(new Error("請輸入發放獎勵"));
      } else {
        callback();
      }
    };
    return {
      prizeList: [{
        value: 1,
        label: "點數"
      },{
        value: 2,
        label: "優惠券"
      },{
        value: 3,
        label: "無"
      },],//獎項選擇
      typeList: [{
        value: 1,
        label: "刮刮樂"
      }],
      ruleList: [{
        value: 0,
        label: "消費次數"
      },{
        value: 1,
        label: "消費金額 - 單筆"
      },{
        value: 2,
        label: "消費金額 - 累計"
      },],
      loading: false,
      title: "",
      type: "",
      formData: {
        id: "",
        name: "", // 名稱
        description: "", // 備注
        introduce: "",//活動介紹
        type: 1,//活動類型
        brand: "", // 品牌
        counter: [], // 店別id
        bannerImage: "",//廣告圖
        backgroundImage: "",//背景圖
        coverImage: "",//封面圖
        prizeImage: "",//獎品圖
        conditionType: "",
        conditionValue: null,
        shopListText: "", // 店別名稱
        time: "",
        playTime: "",
        gameNum: null,
        startTime: "", // 開始時間
        endTime: "", // 結束時間
        isEnable: true, // 是否啓用
        messageTitle: "", // 推播標題
        messageSubTitle: "",//推播副標題
        messageListImageUrl: "",//推播列表圖片
        activityNum: "",//每人可參與活動次數
        playStartTime: "",//遊玩開始時間
        playEndTime: "",//遊玩結束時間
        gameRewardItems: [],
      },
      isMore: false,
      rules: {
        time: [{ required: true, message: "請選擇活動時間", trigger: "blur" }],
        playTime: [{ required: true, message: "請選擇遊玩時間", trigger: "blur" }],
        name: [{ required: true, message: "請輸入名稱", trigger: "blur" }],
        introduce: [{ required: true, message: "請輸入活動介紹", trigger: "blur" }],
        gameNum: [{ required: true, message: "請輸入遊戲次數", trigger: "blur" }],
        type: [{ required: true, message: "請選擇遊戲類型", trigger: "blur" }],
        isEnable: [{ required: true, message: "請選擇是否啟用", trigger: "blur" }],
        messageTitle: [{ required: true, message: "請輸入推播標題", trigger: "blur" }],
        messageSubTitle: [{ required: true, message: "請輸入推播副標題", trigger: "blur" }],
        conditionType: [{ required: true, message: "請選擇遊戲規則", trigger: "blur" }],
        conditionValue: [{ required: true, message: "請輸入觸發條件值", trigger: "blur" }],
        gameRewardItems: [{ required: true, message: "請添加發放獎勵", trigger: "blur" },
          { validator: validateGame, trigger: "blur" },],
        bannerImage: [{
          required: true,
          message: "請上傳圖片",
          trigger: ["change", "blur"],
        },
        ],
        messageListImageUrl: [{
            required: true,
            message: "請上傳圖片",
            trigger: ["change", "blur"],
          },
        ],
        backgroundImage: [{
          required: true,
          message: "請上傳圖片",
          trigger: ["change", "blur"],
        },
        ],
        coverImage: [{
          required: true,
          message: "請上傳圖片",
          trigger: ["change", "blur"],
        },
        ],
        prizeImage: [{
          required: true,
          message: "請上傳圖片",
          trigger: ["change", "blur"],
        },
        ],
      },
      allBrandList: [],
      allRestaurantList: [],
      couponList: [],
      // 店別
      shopData: [],
      shopDialog: {
        show: false,
        search: "",
      },
      shopPager: {
        isSingle: false,
        size: 10,
        current: 1,
        total: 0,
      },
      pickerOptions: {
        disabledDate(time) {
          //此条为设置禁止用户选择今天之前的日期，不包含今天。
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      pickerOptions2: {
        disabledDate(time) {
          //此条为设置禁止用户选择今天之前的日期，包含今天。
          return time.getTime() <= Date.now();
        },
      },
      checkTips: false,
      date: ''
    };
  },
  computed: {
  },
  mounted(){
    var _this = this; //声明一个变量指向Vue实例this，保证作用域一致
    this.timer = setInterval(function(){   
    //设置定时器，每秒执行一次function函数，
    //函数是获取当前时间并给date变量赋值(每秒赋值一次)
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    _this.date = hours+':'+minutes+":"+seconds;  //修改数据date
    },1000);
  },
  beforeDestroy(){
    if(this.timer) {
      clearInterval(this.timer)
    }
  },
  async created() {
    let queryObj = this.$route.query || {};
    const type = queryObj.type;
    this.type = type;
    await this.getBranchData();
    await this.getShopData();
    await this.getTypeSelectList();
    if (type == "create") {
      this.title = "新增遊戲設定";
    } else {
      this.title = "編輯遊戲設定";
      this.$nextTick(() => {
        this.getDetail(queryObj.id);
      });
    }
  },
  methods: {
    valueDate(value){
      console.log(value)
      if(value[0] == value[1]){
        value[1] = ""
        this.$message.error("开始日期和结束日期不能相同");
      }
    },
    selectType(val,index){
      this.formData.gameRewardItems[index].value = null
      if(val.type === 3){
        this.formData.gameRewardItems[index].value = 0
      }
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    //新增獎項
    addPrize(){
      this.formData.gameRewardItems.push({
        type: "",
        value: "",
        rewardName: "",
        proportion: "",
        limit: "",
      })
    },
    // 獲取問卷信息
    getDetail(id) {
      api.gameActivityDetail(id).then((res) => {
        if (res.systemCode === 200) {
          const data = res.data;
          // 店別名稱
          this.formData.shopListText = "";
          let shopListText = "";
          if (data.counter && data.counter.length > 0) {
            this.allRestaurantList.forEach((item) => {
              if (data.counter.includes(item.key.toString())) {
                shopListText += item.value + "，";
              }
            });
            shopListText = shopListText.slice(0, -1);
          } else {
            data.counter = [];
          }
          this.formData = {
            ...data,
            id: id,
            time: [data.startTime,data.endTime],
            playTime: [data.playStartTime,data.playEndTime],
            brand: data.brand ? parseInt(data.brand) : "",
            shopListText,
          };
          console.log(this.formData)
          if (data.brand) this.getShopData(data.brand);
        }
        this.isMore = false;
      });
    },
    // 獲取品牌列表
    getBranchData() {
      api.getBrandBieList().then((res) => {
        if (res.systemCode === 200) {
          this.allBrandList = res.data;
        }
      });
    },
    // 獲取店別列表
    getShopData(Brand = "") {
      let params = {};
      if (Brand) params.BrandId = Brand;
      this.allRestaurantList = [];
      this.isMore = true;
      this.shopVisible = false;
      api.getCounterList(params).then((res) => {
        if (res.systemCode === 200) {
          this.allRestaurantList = res.data;
        }
        this.isMore = false;
      });
    },
    // 獲取優惠券列表
    getTypeSelectList() {
      this.loading = true;
      api.getGameCoupon().then((res) => {
        if (res.systemCode === 200) {
          this.couponList = res.data;
        }
        this.loading = false;
      });
    },
    // 切換品牌
    changeBrand(e) {
      this.checkTips = true;
      // 清空店別
      this.formData.counter = [];
      this.formData.shopListText = "";
      // 獲取店別
      this.getShopData(e);
    },
    // 打開店別彈窗
    openShopDialog() {
      this.shopPager.current = 1;
      this.getPageRes();
      this.shopDialog.show = true;
      // 清空全選
      this.$nextTick(() => {
        if (this.$refs.multipleTable) {
          if (this.formData.shopListText === "") {
            this.$refs.multipleTable.clearSelection();
          } else if (this.formData.counter.length > 0) {
            this.formData.counter.forEach((key) => {
              const List = this.allRestaurantList.find(
                  (item) => item.key == key
              );
              this.$refs.multipleTable.toggleRowSelection(List, true);
            });
          }
        }
      });
    },
    // 取消彈窗
    hideShopDialog() {
      this.shopDialog.show = false;
      this.shopDialog.search = "";
      this.$refs.multipleTable.clearSelection();
    },
    // 確認店別彈窗
    submitShopDialog() {
      this.checkTips = true;
      this.shopDialog.show = false;
      const idArr = [];
      this.formData.shopListText = "";
      if (this.multipleSelection.length > 0) {
        let ctStr = "";
        this.multipleSelection.forEach((item) => {
          ctStr += item.value + "，";
          idArr.push(item.key.toString());
        });
        this.formData.shopListText = ctStr.slice(0, -1);
      }
      this.formData.counter = idArr;
    },
    // 多選店別
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 切換店別分頁
    changeShopPage(val) {
      this.shopPager.current = val;
      this.getPageRes();
    },
    // 搜索店別
    searchShop() {
      this.shopPager.current = 1;
      this.getPageRes();
    },
    // 處理表格數據
    getPageRes() {
      const res = tableDataPagination(
          this.allRestaurantList,
          this.shopPager.current,
          this.shopPager.size,
          this.shopDialog.search
      );
      this.shopData = res.dataList;
      this.shopPager.total = res.dataTotal;
    },
    // 上傳圖片
    async uploadImage(file) {
      if (!this.beforeUpload(file.raw)) return;
      let params = new FormData();
      params.append("file", file.raw);
      await api.uploadImage(params).then((res) => {
        if (res.systemCode === 200) {
          this.formData.messageListImageUrl = res.data;
          this.$message.success("圖片上傳成功");
        }
      });
      this.$refs.gameForm.validateField("messageListImageUrl");
    },
    // 上傳圖片
    async uploadImage2(file) {
      if (!this.beforeUpload(file.raw)) return;
      let params = new FormData();
      params.append("file", file.raw);
      await api.uploadImage(params).then((res) => {
        if (res.systemCode === 200) {
          this.formData.bannerImage = res.data;
          this.$message.success("圖片上傳成功");
        }
      });
      this.$refs.gameForm.validateField("bannerImage");
    },
    // 上傳圖片
    async uploadImage3(file) {
      if (!this.beforeUpload(file.raw)) return;
      let params = new FormData();
      params.append("file", file.raw);
      await api.uploadImage(params).then((res) => {
        if (res.systemCode === 200) {
          this.formData.backgroundImage = res.data;
          this.$message.success("圖片上傳成功");
        }
      });
      this.$refs.gameForm.validateField("backgroundImage");
    },
    // 上傳圖片
    async uploadImage4(file) {
      if (!this.beforeUpload(file.raw)) return;
      let params = new FormData();
      params.append("file", file.raw);
      await api.uploadImage(params).then((res) => {
        if (res.systemCode === 200) {
          this.formData.coverImage = res.data;
          this.$message.success("圖片上傳成功");
        }
      });
      this.$refs.gameForm.validateField("coverImage");
    },
    // 上傳圖片
    async uploadImage5(file) {
      if (!this.beforeUpload(file.raw)) return;
      let params = new FormData();
      params.append("file", file.raw);
      await api.uploadImage(params).then((res) => {
        if (res.systemCode === 200) {
          this.formData.prizeImage = res.data;
          this.$message.success("圖片上傳成功");
        }
      });
      this.$refs.gameForm.validateField("prizeImage");
    },
    // 限制格式和大小
    beforeUpload(file) {
      // console.log(file)
      // debugger
      const acceptArr = ["image/jpeg", "image/png"];
      const isJPG = acceptArr.indexOf(file.type) !== -1;
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上傳圖片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上傳圖片大小不能超過 2MB!");
      }

      // let whIsTrue= false;
      // const gcd = function(a, b) {
      //   if (b === 0) return a;
      //   return gcd(b, a % b);
      // }
      // let {imgHeight,imgWidth} = getImageInfo(file);
      // console.log(imgHeight+"kuan"+ imgWidth)
      // whIsTrue = imgWidth / gcd(imgWidth, imgHeight) + ':' +imgHeight / gcd(imgWidth, imgHeight)  == '16:9' ? true : false;
      // // imgHeight/imgWidth===1 ? true:false; //(1:1=>1)
      // if (!whIsTrue) {
      //     this.$message.error("上傳圖片比例錯誤!");
      // }

      // const ratio = `${width / gcd(width, height)}:${height / gcd(width, height)}`;
      // console.log(ratio); // "16:9"
      //获取宽高
      return isJPG && isLt2M;
    },
    // 刪除圖片
    removeImage(type) {
      if(type===1){
        this.formData.messageListImageUrl = "";
        this.$refs.gameForm.validateField("messageListImageUrl");
      }else if(type===2){
        this.formData.bannerImage = "";
        this.$refs.gameForm.validateField("bannerImage");
      }else if(type===3){
        this.formData.backgroundImage = "";
        this.$refs.gameForm.validateField("backgroundImage");
      }else if(type===4){
        this.formData.coverImage = "";
        this.$refs.gameForm.validateField("coverImage");
      }else if(type===5){
        this.formData.prizeImage = "";
        this.$refs.gameForm.validateField("prizeImage");
      }
    },
    // 提交表單
    onSubmit() {
      // console.log(this.formData)
      // return
      this.checkTips = true;
      this.$refs["gameForm"].validate((valid) => {
        if (valid) {
          let data = this.formData;
          data.gameRewardItems.forEach((item)=>{
            item.proportion = parseInt(item.proportion)
            item.value = parseInt(item.value)
            item.limit = item.limit ? parseInt(item.limit) : null
          })
          const params = {
            id: data.id,
            name: data.name,
            description: data.description,
            brand: data.brand ? data.brand.toString() : "",
            counter: data.counter,
            startTime: data.time[0] ? data.time[0] : null,
            endTime: data.time[1] ? data.time[1] : null,
            isEnable: data.isEnable,
            messageTitle: data.messageTitle,
            messageSubTitle: data.messageSubTitle,
            messageListImageUrl: data.messageListImageUrl,
            playStartTime: data.playTime[0],
            playEndTime: data.playTime[1],
            introduce: data.introduce,
            type: data.type,
            bannerImage: data.bannerImage,
            backgroundImage: data.backgroundImage,
            coverImage: data.coverImage,
            prizeImage: data.prizeImage,
            conditionType: data.conditionType,
            conditionValue: parseInt(data.conditionValue),
            gameNum: parseInt(data.gameNum),
            activityNum: parseInt(data.activityNum),
            gameRewardItems: data.gameRewardItems,
          };
          if (this.type == "create"){
            delete params.id
          }
          api.editGame(params).then((res) => {
            if (res.systemCode === 200) {
              this.$message.success("提交成功");
              this.$router.push({ path: "/cms/gameList" });
            } else {
              // this.$message.error(res.message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 取消
    cancel() {
      this.$router.push({ path: "/cms/gameList" });
    },
  },
};
</script>

<style lang="scss" scoped>
.title_tip{
  color: rgb(245, 108, 108);
  font-size: 14px;
  line-height: 40px;
  padding-left: 20px;
}
.amount-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span {
    margin: 0 10px;
  }
}
.questionnaire-edit {
  .formwrap {
    padding-top: 20px;
    padding-left: 20px;
    .el-form-item {
      max-width: 540px;
      .el-select {
        width: 100%;
      }
      &.table_jl{
        max-width: 100%;
      }
    }
    .tips {
      color: #909399;
      font-size: 12px;
      margin-top: 10px;
      &.wrong {
        color: #f56c6c;
      }
    }
    .el-input-number {
      width: 100%;
      /deep/ .el-input__inner {
        text-align: left;
      }
    }
    .el-form-item.shopWrap {
      width: 100%;
      .el-input {
        flex: 1;
        margin-right: 10px;
      }
      /deep/ .el-form-item__content {
        display: flex;
      }
    }
  }
  .searchBox {
    width: 500px;
    margin-bottom: 10px;
    .el-input {
      width: 250px;
      margin-right: 10px;
    }
  }
}
</style>
