<template>
  <div class="mainwrap couponedit" v-loading="loading">
    <div class="title">優惠券-編輯</div>
    <div class="formwrap">
      <el-form
        :model="couponForm"
        :rules="rules"
        ref="couponForm"
        label-width="150px"
        label-position="left"
      >
        <el-form-item label="名稱" prop="name">
          <el-input
            v-model="couponForm.name"
            placeholder="請輸入名稱"
            disabled
          ></el-input>
        </el-form-item>

        <el-form-item label="適用場景：">
          <div class="fw600">{{ couponForm.note }}</div>
        </el-form-item>

        <el-form-item label="分店">
          <div class="shoptable">
            <el-table border :data="couponForm.useKD" style="width: 100%">
              <el-table-column label="分店">
                <template slot-scope="scope">{{ scope.row }}</template>
              </el-table-column>
            </el-table>
            <el-pagination
              :hide-on-single-page="isSinglePage"
              layout="prev, pager, next"
              :page-size="pageSize"
              :current-page="currentPage"
              :total="tableDataTotal"
            >
            </el-pagination>
          </div>
        </el-form-item>

        <el-form-item label="類型">
          <div class="fw600">{{ couponForm.type }}</div>
        </el-form-item>

        <!-- <el-form-item label="券值">
                <div class="fw600">{{couponForm.value}}</div>
            </el-form-item> -->

        <el-form-item label="使用期限起">
          <div class="fw600">{{ couponForm.activeStart }}</div>
        </el-form-item>

        <el-form-item label="使用期限訖">
          <div class="fw600">{{ couponForm.activeEnd }}</div>
        </el-form-item>

        <el-form-item label="警示水位" prop="waterLine">
          <el-input
            v-model="couponForm.waterLine"
            placeholder="請輸入警示水位"
          ></el-input>
        </el-form-item>

        <el-form-item label="兌換時間">
          <div class="fw600">
            {{ couponForm.receiveStart }} - {{ couponForm.receiveEnd }}
          </div>
        </el-form-item>

        <el-form-item
          label="排序"
          prop="sort"
          v-if="couponForm.receiveType != 1"
        >
          <el-input
            v-model="couponForm.sort"
            @input="onlyNumber($event)"
            placeholder="請輸入"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="優惠日期訖">
                <div class="fw600"></div>
            </el-form-item> -->

        <el-form-item label="領取方式" prop="receiveType">
          <div class="typebox">
            <el-select
              v-model="couponForm.receiveType"
              @change="changeReceiveType"
              placeholder="請選擇領取方式"
            >
              <el-option
                v-for="item in getTypeOptions"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              >
              </el-option>
            </el-select>

            <el-select
              v-if="couponForm.receiveType == 1"
              v-model="couponForm.distributeType"
              @change="changeDistributeType"
              placeholder="請選擇領取方式"
            >
              <el-option
                v-for="item in getTypeSubOptions"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              >
              </el-option>
            </el-select>

            <div
              v-if="
                couponForm.receiveType === 1 && couponForm.distributeType === 3
              "
              class="inlineblock"
            >
              <el-input disabled v-model="couponForm.fileName"></el-input>
              <el-upload
                class="upload-demo"
                action=""
                :auto-upload="false"
                :show-file-list="false"
                :on-change="handleChange2"
                accept=".xls,.xlsx"
              >
                <el-button type="primary" class="upbtn">上傳會員列表</el-button>
              </el-upload>
              <el-button type="primary" @click="downTemplate"
                >下載模板</el-button
              >
            </div>
          </div>
        </el-form-item>

        <el-form-item
          label="是否發送推播信息"
          prop="couponDirectPush"
          v-if="couponForm.distributeType == 3"
        >
          <el-select
            v-model="couponForm.couponDirectPush"
            @change="changeCouponDirectPush"
            placeholder="是否發送推播信息"
          >
            <el-option
              v-for="item in distributeTypeOptions"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <div
            v-if="
              couponForm.couponDirectPush && couponForm.distributeType === 3
            "
            class="inlineblock"
          >
            <el-input disabled v-model="couponForm.linkName"></el-input>
            <el-button type="primary" @click="openLinkVisible">選擇</el-button>
          </div>
        </el-form-item>

        <el-form-item label="圖片" prop="imageUrl">
          <el-upload
            class="img-uploader"
            action=""
            :auto-upload="false"
            :show-file-list="false"
            :on-change="handleChange"
          >
            <img
              v-if="couponForm.imageUrl"
              :src="couponForm.imageUrl"
              class="imgbox"
            />
            <i v-else class="el-icon-plus"></i>
            <div
              @click.stop.prevent
              v-if="couponForm.imageUrl"
              class="deletewrap"
            >
              <i class="el-icon-delete" @click="handleRemove"></i>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上傳jpg/png文件，且不超過2MB，圖片比例1:1
            </div>
          </el-upload>
        </el-form-item>

        <el-form-item label="是否啓用：" prop="isEnable">
          <el-select v-model="couponForm.isEnable" placeholder="请选择">
            <el-option label="请选择" value=""></el-option>
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否允許轉讓：" prop="canTransference">
          <el-select v-model="couponForm.canTransference" placeholder="请选择">
            <el-option label="请选择" value=""></el-option>
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="每人限領張數" prop="limit">
          <el-input-number
            v-model="couponForm.limit"
            :step="1"
            :min="1"
            step-strictly
            :disabled="
              couponForm.distributeType === 1 || couponForm.distributeType === 2
            "
          ></el-input-number>
        </el-form-item>
      </el-form>
    </div>

    <div class="btnwrap">
      <div class="btnlist">
        <el-button type="primary" @click="onSubmit">提交</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </div>

    <el-dialog title="選擇鏈接" :visible.sync="linkVisible" width="40%">
      <div class="linkSearchBox">
        <el-input v-model="linkSearch"></el-input>
        <el-button type="primary" @click="onSearch">搜索</el-button>
      </div>
      <el-table :data="linkVisibleData">
        <el-table-column property="value" label="名稱"></el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button
              :disabled="!scope.row.isEnable"
              @click="selectLink(scope.row)"
              type="primary"
              plain
            >
              選擇
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          :hide-on-single-page="isSinglePage"
          layout="prev, pager, next,jumper"
          @current-change="handleCurrentChange"
          :page-size="pageSize1"
          :current-page="currentPage1"
          :total="tableDataTotal1"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as api from "./api";
import { API } from "@/api/api.js";
import { tableDataPagination, checkURL } from "@/utils/common.js";
export default {
  data() {
    var validateGetType = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("請選擇領取方式"));
      } else {
        if (
          this.couponForm.receiveType === 1 &&
          !this.couponForm.distributeType
        ) {
          callback(new Error("請選擇領取方式"));
        } else if (
          this.couponForm.distributeType === 3 &&
          this.couponForm.fileName === ""
        ) {
          callback(new Error("請上傳會員文件"));
        }
        callback();
      }
    };
    var validateGetType1 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("請選擇是否發送推播信息"));
      } else if (
        this.couponForm.couponDirectPush &&
        !this.couponForm.messageId
      ) {
        callback(new Error("請選擇推播鏈接"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      linkVisible: false,
      linkSearch: "",
      linkVisibleData: [],
      currentPage1: 1,
      pageSize1: 10,
      tableDataTotal1: 0,
      allCouponList: [],

      isSinglePage: true,
      currentPage: 1,
      pageSize: 10,
      tableDataTotal: 0,
      couponForm: {
        id: "",
        name: "",
        imageUrl: "",
        shopTableData: [],
        limit: "",
        receiveType: "",
        distributeType: "",
        isEnable: "",
        memberFile: "",
        fileName: "",
        sort: "",
        couponDirectPush: null,
        messageId: null,
        linkName: "",
        waterLine: "",
        canTransference: "",
      },
      rules: {
        imageUrl: [
          {
            required: true,
            message: "請上傳圖片",
            trigger: ["change", "blur"],
          },
        ],
        limit: [{ required: true, message: "請設置限量", trigger: "change" }],
        receiveType: [
          { required: true, message: "請選擇領取方式", trigger: "change" },
          { validator: validateGetType, trigger: ["change", "blur"] },
        ],
        couponDirectPush: [
          {
            required: true,
            message: "請選擇是否發送推播信息",
            trigger: "change",
          },
          { validator: validateGetType1, trigger: ["change", "blur"] },
        ],
        isEnable: [
          {
            required: true,
            message: "請選擇是否啓用",
            trigger: ["change", "blur"],
          },
        ],
        canTransference: [
          {
            required: true,
            message: "請選擇是否啓用",
            trigger: ["change", "blur"],
          },
        ],
        waterLine: [
          {
            pattern: /^\+?(0|[1-9][0-9]*)$/,
            required: false,
            message: "請輸入0及以上的正整數",
            trigger: ["blur"],
          },
        ],
      },
      getTypeOptions: [
        { id: -1, value: "未設定" },
        { id: 0, value: "會員領取" },
        { id: 1, value: "系統派發" },
      ],
      getTypeSubOptions: [
        { id: 1, value: "首次下載" },
        { id: 2, value: "生日券" },
        { id: 3, value: "指定會員" },
        { id: 4, value: "問卷派發" },
        { id: 5, value: "遊戲獎勵" },
      ],
      distributeTypeOptions: [
        { id: true, value: "是" },
        { id: false, value: "否" },
      ],
    };
  },
  async created() {
    let queryObj = this.$route.query || {};
    await this.getSelectList();
    if (queryObj.type && queryObj.type === "edit" && queryObj.id) {
      this.couponForm.id = queryObj.id;
      this.getCouponDetails(queryObj.id);
    }
  },
  methods: {
    onlyNumber(e) {
      this.couponForm.sort = e.replace(/[^\d]/g, "");
    },

    // 獲取選擇列表
    async getSelectList() {
      this.loading = true;
      await api.couponMessageList().then((response) => {
        if (response.systemCode === 200) {
          this.allCouponList = response.data;
        }
      });
    },
    // selectLink
    selectLink(item) {
      this.couponForm.messageId = item.key;
      this.couponForm.linkName = item.value;
      this.linkVisible = false;
    },
    openLinkVisible() {
      this.currentPage1 = 1;
      this.linkSearch = "";
      this.getPageRes(this.allCouponList);
      this.linkVisible = true;
    },
    // 處理表格數據
    getPageRes(allData) {
      if (allData.length <= 0) {
        this.$message.error("數據錯誤,請稍後重試");
        return false;
      }
      let res = tableDataPagination(
        allData,
        this.currentPage1,
        this.pageSize1,
        this.linkSearch
      );
      this.linkVisibleData = res.dataList;
      this.tableDataTotal1 = res.dataTotal;
    },
    // 翻頁
    handleCurrentChange(val) {
      this.currentPage1 = val;
      this.getPageRes(this.allCouponList);
    },
    // 查詢
    onSearch() {
      this.currentPage1 = 1;
      this.getPageRes(this.allCouponList);
    },

    // 詳情
    getCouponDetails(id) {
      this.loading = true;
      api.getCouponDetails(id).then((res) => {
        this.couponForm = Object.assign({}, this.couponForm, res.data);
        this.couponForm.fileName = res.data.memberFileName;
        for (let item of this.allCouponList) {
          if (this.couponForm.messageId == item.key) {
            this.couponForm.linkName = item.value;
          }
        }
        this.loading = false;
      });
    },
    onSubmit() {
      this.$refs["couponForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          let params = {
            id: this.couponForm.id,
            imageUrl: this.couponForm.imageUrl,
            limit: this.couponForm.limit,
            receiveType: this.couponForm.receiveType,
            distributeType: this.couponForm.distributeType,
            isEnable: this.couponForm.isEnable,
            memberFile: this.couponForm.memberFile,
            memberFileName: this.couponForm.fileName,
            sort: this.couponForm.sort,
            couponDirectPush: this.couponForm.couponDirectPush,
            messageId: this.couponForm.messageId,
            waterLine: this.couponForm.waterLine,
            canTransference: this.couponForm.canTransference,
          };
          for (let key in params) {
            if (params[key] === "") {
              delete params[key];
            }
          }
          api.couponEdit(params).then((res) => {
            if (res.systemCode === 200) {
              this.$message.success("提交成功");
              this.$router.push({ path: "/cms/couponlist" });
            } else {
              // this.$message.error(res.message);
            }
            this.loading = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    cancel() {
      this.$router.push({ path: "/cms/couponlist" });
    },
    // 上傳圖片
    async handleChange(file, fileList) {
      if (!this.beforeUpload(file.raw)) return;
      let params = new FormData();
      params.append("file", file.raw);
      await api.uploadImage(params).then((res) => {
        if (res.systemCode === 200) {
          this.couponForm.imageUrl = res.data;
          this.$message.success("圖片上傳成功");
        }
      });
      this.$refs.couponForm.validateField("imageUrl");
    },
    // 限制格式和大小
    beforeUpload(file) {
      const acceptArr = ["image/jpeg", "image/png"];
      const isJPG = acceptArr.indexOf(file.type) !== -1;
      const isLt2M = file.size / 1024 / 1024 < 2;
      // let whIsTrue= false;
      // 获取宽高
      // let {imgHeight,imgWidth}=await getImageInfo(file);
      // whIsTrue= imgHeight/imgWidth===1 ? true:false; //(1:1=>1)
      // if (!whIsTrue) {
      //     this.$message.error("上傳圖片比例錯誤!");
      // }
      if (!isJPG) {
        this.$message.error("上傳圖片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上傳圖片大小不能超過 2MB!");
      }
      return isJPG && isLt2M;
    },
    handleRemove() {
      this.couponForm.imageUrl = "";
      this.$refs.couponForm.validateField("imageUrl");
    },
    // 上傳會員列表
    async handleChange2(file, fileList) {
      if (!this.beforeFileUpload(file.raw)) return;
      let params = new FormData();
      params.append("file", file.raw);
      params.append("type", 2);
      await api.uploadFile(params).then((res) => {
        if (res.systemCode === 200) {
          this.couponForm.memberFile = res.data;
          this.couponForm.fileName = file.raw.name;
          this.$message.success("文件上傳成功");
        }
      });
    },
    // 限制格式和大小
    beforeFileUpload(file) {
      const isType = file.type === "application/vnd.ms-excel";
      const isTypeComputer =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const fileType = isType || isTypeComputer;
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!fileType) {
        this.$message.error("上传文件只能是xls/xlsx格式！");
      }
      if (!isLt2M) {
        this.$message.error("上傳圖片大小不能超過 2MB!");
      }
      return fileType && isLt2M;
    },
    changeReceiveType(val) {
      console.log(val);
      this.couponForm.distributeType = "";
      if (val == 1) {
        this.couponForm.sort = null;
      }
    },
    changeDistributeType(val) {
      if (val === 1 || val === 2) {
        this.couponForm.limit = 1;
      }
    },
    changeCouponDirectPush(val) {},
    // 下載模板
    downTemplate() {
      window.open(API.CouponMemberTemplate, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.couponedit {
  .formwrap {
    padding-top: 20px;
    padding-left: 20px;
    width: 500px;
    .inlineblock {
      margin-top: 10px;
      width: 500px;
      .upbtn {
        margin-right: 10px;
      }
      .el-input {
        width: 50%;
        margin-right: 10px;
      }
      .upload-demo {
        display: inline-block;
      }
    }
    .typebox {
      width: 750px;
    }
    .fw600 {
      font-weight: 600;
    }
  }
  .linkSearchBox {
    width: 500px;
    margin-bottom: 10px;
    .el-input {
      width: 250px;
      margin-right: 10px;
    }
  }
}
</style>
